<template>
  <v-scale-screen width="1920" height="1080">
    <div class="screen-container">
      <div class="header">
        <div class="logo">
          <img :src="$toUrl('edp-logo.png')" alt="">
        </div>
        <div class="title">项目状态汇总</div>
      </div>
      <div class="center">
        <div class="project">
          <div
              class="project-items"
              v-for="(item,index) in projectList"
              :key="item.oid"
              :class="{active:currentIndex===index}"
              @click="currentIndex=index"
          >
            <div class="title ellipsis-text">{{ item.project_name }}</div>
          </div>
          <template v-if="(17-projectList.length)>0">
            <div
                class="project-items"
                v-for="item in (17-projectList.length)"
                :key="item"
            >
              <div class="title ellipsis-text"></div>
            </div>
          </template>
        </div>
        <task-matters :task-list="taskList" />
        <div class="responsible-person-project">
          <project-carousel v-if="swiperProjectList.length>0" :swiper-project-list="swiperProjectList" />
          <div class="bottom">
            <div class="announcement" ref="scrollBox">
              <div class="title">{{ projectDetail?.project_name }}公告</div>
              <div class="content" ref="content">{{ projectDetail?.project_intro }}</div>
            </div>
            <div class="completion">
              <div class="title">{{ projectDetail?.project_name }}完成情况</div>
              <div class="center">
                <div class="ring">
                  <a-progress :percent="running_rate+complete_rate" :success-percent="complete_rate" :width="250"
                              type="circle"
                              strokeColor="#FFA522">
                    <template #format>
                      <div class="percentage">
                        <span class="num">{{ projectDetail?.complete_rate }}</span>
                        <span class="percentSign">%</span>
                      </div>
                      <div class="text">完成率</div>
                    </template>
                  </a-progress>
                </div>
                <div class="info">
                  <div class="item">
                    <div class="dot yellow"></div>
                    <div class="text">未完成率: {{ projectDetail?.running_rate }}%</div>
                  </div>
                  <div class="item">
                    <div class="dot red"></div>
                    <div class="text">延误率: {{ projectDetail?.delay_rate }}%</div>
                  </div>
                  <div class="item">
                    <div class="dot blue"></div>
                    <div class="text">已完成率: {{ projectDetail?.complete_rate }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-scale-screen>
</template>

<script>
import { fetch } from "@/utils/request";
import ProjectCarousel from "@/view/dataScreen/components/project-carousel.vue";
import TaskMatters from "@/view/dataScreen/components/task-matters.vue";

export default {
  name: 'DataScreen',
  components: { TaskMatters, ProjectCarousel },
  data() {
    return {
      projectList: [],
      swiperProjectList: [],
      taskList: [],
      currentIndex: 0,
      timerId1: null,
      timerId2: null,
    }
  },
  async created() {
    await this.getProjectData()
    await this.getTaskData(this.projectList[this.currentIndex].oid)
    this.setupAutoScroll()
  },
  mounted() {
    // 切换currentIndex的定时器
    this.timerId1 = setInterval(() => {
      this.currentIndex++
      if (this.currentIndex === this.projectList.length) this.currentIndex = 0
    }, 8000)
    // 刷新页面的定时器
    this.timerId1 = setInterval(() => {
      location.reload()
    }, 2 * 60 * 60 * 1000)
  },
  watch: {
    currentIndex: {
      handler() {
        this.getTaskData(this.projectList[this.currentIndex].oid)
        const allItems = document.querySelectorAll(".project-items")
        allItems[this.currentIndex].scrollIntoView(false)
      }
    }
  },
  computed: {
    projectDetail() {
      return this.projectList[this.currentIndex]
    },
    // 总
    totalRate() {
      return Number(this.projectDetail?.running_rate) + Number(this.projectDetail?.delay_rate) + Number(this.projectDetail?.complete_rate)
    },
    // 未完成
    running_rate() {
      return (Number(this.projectDetail?.running_rate) / this.totalRate) * 100
    },
    // 已完成
    complete_rate() {
      return (Number(this.projectDetail?.complete_rate) / this.totalRate) * 100
    }
  },
  methods: {
    async getProjectData() {
      const res = await fetch('post', '/data/project')
      if (res.status === 0) {
        this.projectList = res.data
        for (let i = 0; i < Math.ceil(res.data.length / 15); i++) {
          this.swiperProjectList[i] = res.data.slice(i * 15, i * 15 + 15)
        }
      }
    },
    async getTaskData(oid) {
      const res = await fetch('post', '/data/issue', { prjoid: oid, page_size: 25 })
      if (res.status === 0) {
        // if (res.data.length < 25) {
        //   res.data = res.data.concat(Array(25 - res.data.length).fill({}))
        // }
        this.taskList = res.data
      }
    },
    setupAutoScroll() {
      const content = this.$refs.content
      content.addEventListener("scroll", () => {
        if (content.scrollHeight - content.scrollTop === content.clientHeight) {
          content.scrollTop = 0
        }
      })
      content.scrollTop = 0
      setInterval(() => {
        content.scrollTop += 1
      }, 300)
    }
  },
  beforeDestroy() {
    if (this.timerId1) {
      clearInterval(this.timerId1)
    }
    if (this.timerId2) {
      clearInterval(this.timerId2)
    }
  },
}
</script>

<style lang="less" scoped>
.screen-container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  height: 100vh;
  padding: 14px 14px 0;

  .header {
    display: flex;
    align-items: center;
    height: 91px;
    background: #24262D;

    .logo {
      width: 158px;
      height: 64px;
      margin: 0 37px 0 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-weight: 700;
      font-size: 40px;
      color: #FFFFFF;
    }
  }

  > .center {
    display: flex;
    flex: 1;

    > .project {
      position: relative;
      width: 208px;
      height: 970px;
      overflow: auto;
      padding-top: 14px;
      margin-right: 12px;
      border-top: 2px solid #3779FD;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        display: none;
      }

      .project-items {
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #24262D;

        .title {
          height: 21px;
          font-weight: 700;
          font-size: 16px;
          color: #FFFFFF;
        }

        &.active {
          background: #3779FD;
        }
      }
    }

    .responsible-person-project {
      display: flex;
      flex-direction: column;
      width: 1407px;
      height: 100%;
      border-top: 2px solid #F52E5A;
      box-sizing: border-box;

      .bottom {
        display: flex;
        flex: 1;

        .announcement {
          box-sizing: border-box;
          width: 840px;
          background: #24262D;
          padding: 20px 0 0 20px;
          margin-right: 12px;

          .title {
            font-weight: 700;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 10px;
          }

          .content {
            white-space: pre-line;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 32px;
            height: 300px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }

        .completion {
          width: 556px;
          background: #24262D;

          .title {
            font-weight: 700;
            font-size: 18px;
            color: #FFFFFF;
            margin: 20px 0 0 20px;
          }

          .center {
            display: flex;
            margin: 46px 0 0 25px;

            /deep/ .ant-progress {
              .ant-progress-circle {
                .ant-progress-circle-trail {
                  stroke: #F22D59 !important;
                }

                path:nth-child(3) {
                  stroke: #3678FD !important;
                }
              }

              .ant-progress-text {
                .percentage {
                  .num {
                    color: #ffffff;
                    font-size: 42px;
                    font-weight: 700;
                  }

                  .percentSign {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 700;
                  }
                }

                .text {
                  margin-top: 25px;
                  font-weight: 700;
                  font-size: 18px;
                  color: #FFFFFF;
                }
              }
            }

            .info {
              margin-top: 33px;
              margin-left: 28px;

              .item {
                display: flex;
                align-items: center;
                margin-bottom: 48px;

                .dot {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  margin-right: 8px;

                  &.blue {
                    background: #3678FD;
                  }

                  &.red {
                    background-color: #F22D59;
                  }

                  &.yellow {
                    background-color: #FFA522;
                  }
                }

                .text {
                  font-weight: 700;
                  font-size: 24px;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
