<template>
  <div class="project-carousel">
    <div class="title">负责人项目</div>
    <a-carousel :dots="false" autoplay :autoplaySpeed="10000">
      <div class="project" v-for="(swiperProject,swiperIndex) in swiperProjectList"
           :key="`swiper${swiperIndex}`">
        <div class="project-item" v-for="item in swiperProject" :key="item.oid">
          <div class="title ellipsis-text">{{ item.project_name }}</div>
          <div class="schedule"
               :class="{red:item.delay_rate>90,yellow:item.delay_rate<90&&item.delay_rate>60,blue:item.delay_rate<60}">
            <span class="num">{{ item.complete_rate }}</span>
            <span class="percent-sign" v-if="item.complete_rate">%</span>
          </div>
          <div class="name">{{ item.admin_name }}</div>
        </div>
        <template v-if="(15-swiperProject.length)>0">
          <div class="project-item" v-for="(item,index) in (15-swiperProject.length)" :key="index">
            <div class="title"></div>
            <div class="schedule">
              <span class="num"></span>
              <span class="percent-sign"></span>
            </div>
            <div class="name"></div>
          </div>
        </template>
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  props: {
    swiperProjectList: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="less" scoped>
.project-carousel {
  margin-bottom: 12px;

  > .title {
    font-weight: 700;
    font-size: 18px;
    height: 51px;
    line-height: 51px;
    color: #FFFFFF;
    margin-left: 14px;
  }

  .project {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 167px);
    grid-gap: 12px;

    .project-item {
      width: 272px;
      background: #24262D;

      &:nth-child(n+16) {
        display: none;
      }

      .title {
        height: 24px;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
        margin: 20px 0 0 20px;
      }

      .schedule {
        margin: 20px 0 0 20px;

        &.blue {
          color: #3779fd;
        }

        &.red {
          color: #F22D59;
        }

        &.yellow {
          color: #FFA522;
        }

        .num {
          font-size: 42px;
          font-weight: 700;
        }

        .percent-sign {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .name {
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
        margin: 5px 0 0 20px;
      }
    }
  }
}
</style>
