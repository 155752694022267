<template>
  <div class="task-matters">
    <div class="title">任务事项</div>
    <div class="info">
      <div class="left">负责人</div>
      <div class="right">任务名称</div>
    </div>
    <div class="item" v-for="item in taskList" :key="item.oid">
      <div class="left ellipsis-text">{{ item.creator_name }}</div>
      <div class="right ellipsis-text">{{ item.issue_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskList: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="less" scoped>
.task-matters {
  width: 252px;
  height: 100%;
  border-top: 2px solid #3779FD;
  margin-right: 12px;
  box-sizing: border-box;

  .title {
    font-weight: 700;
    font-size: 18px;
    height: 51px;
    line-height: 51px;
    color: #FFFFFF;
    margin-left: 14px;
  }

  .info {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 16px;
    color: #FFFFFF;

    .left {
      width: 65px;
      margin-left: 14px;
    }

    .right {
      flex: 1;
      margin-left: 24px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 16px;
    color: #FFFFFF;

    .left {
      width: 65px;
      margin-left: 14px;
    }

    .right {
      flex: 1;
      margin-left: 24px;
    }

    &:nth-of-type(2n+1) {
      background-color: #24262D;
    }
  }
}
</style>
